import iBuild from '../../../../../types/build/iBuild';
import { DatePicker } from '../../../../frameWork/DateTimePicker';
import styled from 'styled-components';
import InlineEdit, { ViewWrapper } from '../../../../frameWork/InlineEdit';
import { useState } from 'react';
import BuildService from '../../../../../services/build/BuildService';
import moment from 'moment';
import Toaster from '../../../../common/Toaster';
import Flex from '../../../../frameWork/Flex';
import Tokens from '../../../../frameWork/Tokens';
import UtilsService from '../../../../../services/UtilsService';

type iBuildDetailsContractOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: start;
  form {
    margin-top: 0px;
  }
  .price-el-before {
    padding: 0 0 0 ${Tokens('space.050', '1rem')};
  }
`;

const BuildDetailsContractOverview = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iBuildDetailsContractOverview) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (fieldName: string, newValue: string | null) => {
    setIsSaving(true);
    BuildService.update(build.id, {
      [fieldName]: newValue,
    })
      .then((resp) => {
        onBuildUpdated && onBuildUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Wrapper>
      <Flex className={'flex-wrap align-items-flex-start gap-05 full-width'}>
        <DatePicker
          label={'Contract Date'}
          isDisabled={isSaving || isDisabled}
          appearance={'subtle'}
          value={`${build.contractDate || ''}`}
          onChange={(selected) =>
            handleUpdate(
              'contractDate',
              selected === null ? null : moment(selected).toISOString(),
            )
          }
        />
        <InlineEdit
          label={'Contract Days'}
          isDisabled={isSaving || isDisabled}
          placeHolder={'Number of days in the contract'}
          value={`${build.contractDays || ''}`}
          onConfirm={(days) =>
            handleUpdate(
              'contractDays',
              `${days || ''}`.trim() === '' ? null : days,
            )
          }
        />
      </Flex>

      <Flex className={'flex-wrap align-items-flex-start gap-05 full-width'}>
        {[
          {
            name: 'Base Price',
            value: `${build.basePrice || ''}`.trim(),
            fieldName: 'basePrice',
          },
          {
            name: 'Variance Price',
            value: `${build.variancePrice || ''}`.trim(),
            fieldName: 'variancePrice',
          },
          {
            name: 'Site Allowance Price',
            value: `${build.siteAllowancePrice || ''}`.trim(),
            fieldName: 'siteAllowancePrice',
          },
          {
            name: 'Contract Price',
            value: `${build.contractPrice || ''}`.trim(),
            fieldName: 'contractPrice',
          },
        ].map((item) => {
          return (
            <InlineEdit
              key={item.fieldName}
              readViewFitContainerWidth
              label={item.name}
              isDisabled={isSaving || isDisabled}
              placeHolder={item.name}
              value={item.value}
              editViewProps={{
                elemBeforeInput: <div className={'price-el-before'}>$</div>,
              }}
              readView={() => {
                const value = UtilsService.formatIntoCurrency(
                  item.value === '' ? null : Number(item.value),
                );
                return (
                  <ViewWrapper className={value === '' ? 'default-view' : ''}>
                    {value === '' ? item.name : value}
                  </ViewWrapper>
                );
              }}
              onConfirm={(price) =>
                handleUpdate(
                  item.fieldName,
                  `${price || ''}`.trim() === '' ? null : price,
                )
              }
            />
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export default BuildDetailsContractOverview;
