import iBaseType from '../iBaseType';
import iAttributeSet from './iAttributeSet';

export enum AttributeTypes {
  DROPDOWN = 'dropdown',
  TEXT = 'text',
  INPUT_TEXT = 'input_text',
  INPUT_NUMBER = 'input_number',
  CHECKBOX = 'checkbox',
}

type iAttribute = iBaseType & {
  name: string;
  type: string;
  attributeSetCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
  sort: number;
  isRequired: boolean;
  defaultValue: string | null;
  AttributeSet?: iAttributeSet;
};

export default iAttribute;
