import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import Spinner from '../frameWork/Spinner';
import AttributeService from '../../services/attribute/AttributeService';
import AttributeValueService from '../../services/attribute/AttributeValueService';
import AttributeItemService from '../../services/attribute/AttributeItemService';
import iAttribute from '../../types/attribute/iAttribute';
import Tokens from '../frameWork/Tokens';
import AttributeValueInput from './AttributeValueInput';
import iAttributeValue from '../../types/attribute/iAttributeValue';
import MathHelper from '../../helpers/MathHelper';
import iAttributeItem from '../../types/attribute/iAttributeItem';

type iAttributeValueEditSingleType = {
  attributeSetCode: AttributeSetCodes;
  className?: string;
  testId?: string;
  entityId: string;
  entityName: string;
  isDisabled?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.100', '0.5rem')};
  align-items: start;
  form:first-child {
    margin-block-start: ${Tokens('space.150', '0.75rem')};
  }
  .attr-editor {
    min-width: 10rem;
  }
`;

type iaAttributeValueMap = { [key: string]: iAttributeValue };
const AttributeValueEditSingleItem = ({
  attributeSetCode,
  className = '',
  testId = '',
  entityId,
  entityName,
  isDisabled = false,
}: iAttributeValueEditSingleType) => {
  const testIdStr = `${testId}-attribute-value-edit-single-item`;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const [attributeItems, setAttributeItems] = useState<iAttributeItem[]>([]);
  const [attributeValueMap, setAttributeValueMap] =
    useState<iaAttributeValueMap | null>(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let isCanceled = false;

    const getData = async () => {
      const results = await Promise.all([
        AttributeService.getAll({
          where: JSON.stringify({
            isActive: true,
            attributeSetCode: attributeSetCode,
          }),
          sort: 'sort:ASC',
          perPage: 999999,
        }),
        AttributeItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            attributeSetCode: attributeSetCode,
            entityId,
            entityName,
          }),
          perPage: 1,
        }),
      ]);
      const attrs = results[0].data || [];
      const attrItems = results[1].data || [];
      const itemIds = attrItems.map((row) => row.id);
      const attributeValues =
        itemIds.length <= 0
          ? []
          : (
              await AttributeValueService.getAll({
                where: JSON.stringify({
                  isActive: true,
                  attributeSetCode: attributeSetCode,
                  itemId: itemIds,
                }),
                perPage: 999999,
                include: 'Attribute,Item',
              })
            ).data;

      if (isCanceled) return;
      setAttributes(attrs);
      setAttributeItems(attrItems);
      setAttributeValueMap(
        attributeValues.reduce((map: iaAttributeValueMap, attributeValue) => {
          const attributeId = `${attributeValue.attributeId || ''}`.trim();
          if (attributeId === '') {
            return map;
          }
          return {
            ...map,
            [attributeValue.attributeId]: attributeValue,
          };
        }, {}),
      );
      return [...results, attributeValues];
    };
    setIsLoading(true);
    getData()
      .catch((err) => {
        if (isCanceled) return;
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [attributeSetCode, count]);

  type iGetSaveFnc = {
    attributeId: string;
    newValue: string | null;
    attributeItem?: iAttributeItem;
    attributeValue?: iAttributeValue;
  };
  const getSaveFnc = ({
    attributeId,
    newValue,
    attributeItem,
    attributeValue,
  }: iGetSaveFnc) => {
    const attributeValueId = `${attributeValue?.id || ''}`.trim();
    if (attributeValueId !== '' && newValue === null) {
      return () => AttributeValueService.deactivate(attributeValueId);
    }

    return attributeValue === undefined
      ? async () => {
          const item = attributeItem
            ? attributeItem
            : await AttributeItemService.create({
                entityName,
                entityId,
                attributeSetCode: attributeSetCode,
              });
          return AttributeValueService.create({
            attributeId,
            itemId: item.id,
            attributeSetCode: attributeSetCode,
            value: newValue,
          });
        }
      : () =>
          AttributeValueService.update(attributeValueId, {
            value: `${newValue || ''}`.trim(),
          });
  };

  const handleUpdate = (props: iGetSaveFnc) => {
    const { attributeValue, newValue } = props;
    if (
      `${attributeValue?.value || ''}`.trim() === `${newValue || ''}`.trim()
    ) {
      return;
    }
    const saveFnc = getSaveFnc(props);

    setIsSaving(true);
    saveFnc()
      .then(() => {
        setCount(MathHelper.add(count, 1));
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getContent = () => {
    // if there is an error of getting data, then don't display the input boxes.
    if (isLoading || attributeValueMap === null) {
      return <Spinner />;
    }
    return (
      <>
        {attributes.map((attribute) => {
          return (
            <AttributeValueInput
              isDisabled={isSaving || isLoading || isDisabled}
              className={'attr-editor'}
              attribute={attribute}
              key={attribute.id}
              attributeValue={
                attribute.id in attributeValueMap
                  ? attributeValueMap[attribute.id]
                  : undefined
              }
              onChange={(newValue) =>
                handleUpdate({
                  attributeId: attribute.id,
                  newValue,
                  attributeItem: attributeItems[0],
                  attributeValue:
                    attribute.id in attributeValueMap
                      ? attributeValueMap[attribute.id]
                      : undefined,
                })
              }
            />
          );
        })}
      </>
    );
  };
  return (
    <Wrapper
      className={`attribute-value-single-set ${className}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeValueEditSingleItem;
