import ServiceHelper from '../helper/ServiceHelper';
import iAttributeValue from '../../types/attribute/iAttributeValue';

const endPoint = '/attributeValue';
const AttributeValueService = {
  getAll: ServiceHelper.getPaginatedAll<iAttributeValue>(endPoint),
  get: ServiceHelper.getById<iAttributeValue>(endPoint),
  create: ServiceHelper.create<iAttributeValue>(endPoint),
  update: ServiceHelper.updateById<iAttributeValue>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttributeValue>(endPoint),
};

export default AttributeValueService;
