import iPaginatedResult from '../../types/iPaginatedResult';
import Select, { iOption, iOptionWithData, iSelect } from './Select';
import { useCallback, useEffect, useState } from 'react';
import * as _ from 'lodash';
import Toaster from '../common/Toaster';
import { iConfigParams } from '../../services/AppService';
import MathHelper from '../../helpers/MathHelper';

export type iPreloadAsyncSelectProps = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iSelect<any>,
  'isMulti' | 'inputValue' | 'onInputChange' | 'onMenuClose' | 'onMenuOpen'
> & {
  isMulti?: boolean;
};

type iGetFnParams = iConfigParams & {
  searchText?: string;
  currentPage?: number;
  perPage?: number;
};

export type iPreloadedAsyncSelector<T> = iPreloadAsyncSelectProps & {
  isLoadingData?: boolean;
  fetchSize?: number;
  getValuesFn: (values: string[]) => Promise<iPaginatedResult<T>>;
  getFn: (
    params?: iGetFnParams,
    isGettingForValue?: boolean,
  ) => Promise<iPaginatedResult<T>>;
  renderOption?: (data: T) => iOptionWithData<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsUniqBy?: (data: T) => any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const covertToOption = (data: any, index: number) => ({
  label: 'name' in data ? `${data.name}` : `Option: ${index}`,
  value: 'id' in data ? `${data.id}` : `${index}`,
  data,
});

const PreloadedAsyncSelector = <T,>({
  getFn,
  getValuesFn,
  fetchSize = 10,
  renderOption,
  optionsUniqBy,
  value,
  values,
  isLoadingData = false,
  ...props
}: iPreloadedAsyncSelector<T>) => {
  const [data, setData] = useState<iPaginatedResult<T> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingValues, setIsLoadingValues] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setsSelectedOptions] = useState<iOption[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    getFn({ currentPage, perPage: fetchSize, searchText })
      .then((resp) => {
        if (isCanceled) {
          return;
        }

        setData({
          ...resp,
          data: _.uniqBy(
            currentPage <= 1
              ? resp.data || []
              : [...(data?.data || []), ...(resp.data || [])],
            optionsUniqBy ? optionsUniqBy : 'id',
          ),
        });
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [currentPage, fetchSize, searchText]);

  useEffect(() => {
    let isCanceled = false;

    const valueStr = `${value || ''}`.trim();
    const valueStrs = (values || [])
      .map((val: string) => `${val || ''}`.trim())
      .filter((v: string) => v !== '');

    const vals = valueStr === '' ? valueStrs : [valueStr];
    if (vals.length <= 0) {
      setsSelectedOptions([]);
      return;
    }
    setIsLoadingValues(true);
    getValuesFn(vals)
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setsSelectedOptions(
          (resp.data || []).map((row, index) =>
            renderOption ? renderOption(row) : covertToOption(row, index),
          ),
        );
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoadingValues(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [value, values]);

  const debouncedSetSearchText = useCallback(
    _.debounce((newInput: string) => {
      setCurrentPage(1);
      setSearchText(newInput);
    }, 500),
    [],
  );

  const handleInputChange = (newInput: string) => {
    setInputValue(newInput); // Immediately update the inputValue for display

    if (currentPage === 1 && searchText === '') {
      debouncedSetSearchText(newInput); // Debounce the API call
    } else {
      setSearchText(newInput); // Immediate update for other cases
    }
  };

  return (
    <Select
      {...props}
      value={selectedOptions}
      inputValue={inputValue}
      options={(data?.data || []).map((row, index) => {
        return renderOption ? renderOption(row) : covertToOption(row, index);
      })}
      isLoading={isLoadingData || isLoading || isLoadingValues}
      onInputChange={(newInput) => handleInputChange(newInput)}
      onMenuScrollToBottom={() => {
        if ((data?.pages || 0) > currentPage) {
          setCurrentPage(MathHelper.add(currentPage, 1));
          return;
        }
      }}
    />
  );
};

export default PreloadedAsyncSelector;
