import iAttributeValue from '../../types/attribute/iAttributeValue';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import styled from 'styled-components';
import TextField from '../frameWork/TextField';
import Select, { convertSameValueToOption } from '../frameWork/Select';
import { getErrorProps, iErrorMap } from '../form/FormError';
import Toggle from '../frameWork/Toggle';
import StringHelper from '../../helpers/StringHelper';

type iAttributeValueInput = {
  attribute: iAttribute;
  className?: string;
  attributeValue?: iAttributeValue;
  isDisabled?: boolean;
  onChange: (newValue: string | null, errors: iErrorMap) => void;
  errorMap?: iErrorMap;
  appearance?: 'subtle' | 'default';
};
const Wrapper = styled.div``;

export const getRequiredAttributeErrors = (
  attribute: iAttribute,
  attributeValue?: iAttributeValue,
) => {
  const selectedValue = `${attributeValue?.value || ''}`.trim();
  return attribute.isRequired && selectedValue === ''
    ? { [attribute.id]: [`${attribute.name} is required`] }
    : {};
};

const AttributeValueInput = ({
  attribute,
  attributeValue,
  onChange,
  className = '',
  isDisabled = false,
  errorMap = {},
  appearance = 'subtle',
}: iAttributeValueInput) => {
  const getInputErrorProps = (error: iErrorMap) => {
    return getErrorProps({
      fieldName: attribute.id,
      error: error,
    });
  };

  const getComponent = () => {
    const selectedValue = attributeValue?.value || '';
    const requiredError = getRequiredAttributeErrors(attribute, attributeValue);
    const errors: iErrorMap = { ...errorMap, ...requiredError };
    switch (attribute.type) {
      case AttributeTypes.CHECKBOX: {
        return (
          <Toggle
            separatedLines
            label={attribute.name}
            isDisabled={isDisabled}
            isRequired={attribute.isRequired}
            {...getInputErrorProps(errors)}
            isChecked={selectedValue === 'Y'}
            onChange={(event) => {
              onChange(event.target.checked ? `Y` : 'N', errors);
            }}
          />
        );
      }
      case AttributeTypes.DROPDOWN: {
        return (
          <Select
            placeholder={'select ...'}
            label={attribute.name}
            isDisabled={isDisabled}
            isClearable={attribute.isRequired !== true && selectedValue !== ''}
            appearance={Object.keys(errors).length > 0 ? 'default' : appearance}
            isRequired={attribute.isRequired}
            {...getInputErrorProps(errors)}
            value={convertSameValueToOption(selectedValue)}
            options={(attribute.settings?.options || []).map((opt: string) =>
              convertSameValueToOption(opt),
            )}
            onChange={(selected) => {
              onChange(selected ? `${selected.value}` : null, errors);
            }}
          />
        );
      }
      case AttributeTypes.INPUT_NUMBER: {
        if (!StringHelper.isNumeric(selectedValue)) {
          errors[attribute.id] = [`${attribute.name} needs to be a number`];
        }
        return (
          <TextField
            placeholder={'type in here...'}
            label={attribute.name}
            isDisabled={isDisabled}
            isRequired={attribute.isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(event) => {
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
                errors,
              );
            }}
          />
        );
      }
      default: {
        return (
          <TextField
            placeholder={'type in here...'}
            label={attribute.name}
            isDisabled={isDisabled}
            isRequired={attribute.isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(event) => {
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
                errors,
              );
            }}
          />
        );
      }
    }
  };

  return (
    <Wrapper className={`AttributeValueEditor ${className}`}>
      {getComponent()}
    </Wrapper>
  );
};

export default AttributeValueInput;
