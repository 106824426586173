import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { getErrorProps, iErrorMap } from '../form/FormError';
import { useEffect, useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import Toaster from '../common/Toaster';
import iAttributeItem from '../../types/attribute/iAttributeItem';
import AttributeItemService from '../../services/attribute/AttributeItemService';
import BuildAreaService from '../../services/build/BuildAreaService';
import AttributeService from '../../services/attribute/AttributeService';
import Spinner from '../frameWork/Spinner';
import HouseAreaSelector from '../houseArea/HouseAreaSelector';
import { iOption } from '../frameWork/Select';
import TextField from '../frameWork/TextField';
import StringHelper from '../../helpers/StringHelper';
import iAttribute from '../../types/attribute/iAttribute';
import AttributeValueInput from './AttributeValueInput';
import AttributeValueService from '../../services/attribute/AttributeValueService';

export type iAttributeItemEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  entityId: string;
  entityName: string;
  attributeSetCode: string;
  attributeItem?: iAttributeItemWithValueMap;
  isDisabled?: boolean;
  needQty?: boolean;
  needArea?: boolean;
  onSaved?: (saved: iAttributeItem, isCreated: boolean) => void;
};

const AttributeItemEditPopupBtn = ({
  attributeItem,
  attributeSetCode,
  isDisabled = false,
  needQty = true,
  needArea = true,
  onSaved,
  entityName,
  entityId,
  ...props
}: iAttributeItemEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [buildAreaIds, setBuildAreaIds] = useState<string[]>([]);
  const [attributes, setAttributes] = useState<iAttribute[]>([]);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      BuildAreaService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityId,
          entityName,
        }),
        perPage: 999999,
      }),
      AttributeService.getAll({
        where: JSON.stringify({
          isActive: true,
          attributeSetCode: attributeSetCode,
        }),
        sort: 'sort:ASC',
        perPage: 999999,
      }),
    ])
      .then((result) => {
        if (isCanceled) return;
        setBuildAreaIds((result[0].data || []).map((area) => area.houseAreaId));
        setAttributes(result[1].data || []);
      })
      .catch((err) => {
        if (isCanceled) return;
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, []);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
  };

  const handleChange = (fieldName: string, value: string | null) => {
    setEditingData({
      ...editingData,
      [fieldName]: value,
    });
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const data = { ...(attributeItem || {}), ...editingData };
    if (needQty === true) {
      if (`${data.qty || ''}`.trim() === '') {
        errors.qty = ['Qty is required'];
      }
      if (!StringHelper.isNumeric(`${data.qty || ''}`.trim())) {
        errors.qty = ['Qty needs to be a number'];
      }
    }
    if (needArea === true) {
      if (`${data.houseAreaId || ''}`.trim() === '') {
        errors.houseAreaId = ['Area is required'];
      }
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const attributeItemId = `${attributeItem?.id || ''}`.trim();
    const { valuesMap, ...data } = editingData;
    const itemData = {
      ...data,
      attributeSetCode,
      entityId,
      entityName,
    };

    const createAllValues = (itemId: string) => {
      const promises = Object.keys(valuesMap || {})
        .filter((attributeId) => {
          if (!(attributeId in valuesMap)) {
            return false;
          }
          const attValue = valuesMap[attributeId] || {};
          if (attValue.value === undefined || attValue.value === null) {
            return false;
          }
          return true;
        })
        .map((attributeId) => {
          const attValue = valuesMap[attributeId] || {};
          const attValueId = `${attValue.id || ''}`.trim();
          if (attValueId !== '') {
            return attValue.value === null
              ? AttributeValueService.deactivate(attValueId)
              : AttributeValueService.update(attValueId, {
                  value: attValue.value,
                });
          }
          return AttributeValueService.create({
            attributeId,
            attributeSetCode,
            itemId,
            value: attValue.value,
          });
        })
        .filter((promise) => promise !== null);
      return Promise.all(promises);
    };
    setIsSaving(true);
    const getFnc =
      attributeItemId === ''
        ? async () => {
            const item = await AttributeItemService.create(itemData);
            await createAllValues(item.id);
            return item;
          }
        : async () => {
            const updatedItem = await AttributeItemService.update(
              attributeItemId,
              itemData,
            );
            await createAllValues(attributeItemId);
            return updatedItem;
          };

    getFnc()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, attributeItemId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getBody = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        {needArea && (
          <HouseAreaSelector
            label={'Area'}
            isRequired
            testId={'item-houseAreaId'}
            houseAreaIds={buildAreaIds}
            isDisabled={isSaving || isDisabled}
            value={editingData.houseAreaId || attributeItem?.houseAreaId}
            {...getErrorProps({ fieldName: 'houseAreaId', error: errorMap })}
            onChange={(selected: iOption) =>
              handleChange(
                'houseAreaId',
                selected.value ? `${selected.value || ''}`.trim() : null,
              )
            }
          />
        )}
        {needQty && (
          <TextField
            label={'Qty'}
            isRequired
            testId={'item-qty'}
            isDisabled={isSaving || isDisabled}
            placeholder={'Quantity'}
            value={editingData.qty || attributeItem?.qty || ''}
            {...getErrorProps({ fieldName: 'qty', error: errorMap })}
            onChange={(event) =>
              handleChange(
                'qty',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
              )
            }
          />
        )}
        {attributes.map((attribute) => {
          const valuesMap =
            editingData.valuesMap || attributeItem?.valuesMap || {};
          const attValue =
            attribute.id in valuesMap ? valuesMap[attribute.id] : null;
          return (
            <AttributeValueInput
              key={attribute.id}
              attribute={attribute}
              appearance={'default'}
              isDisabled={isSaving || isDisabled}
              attributeValue={attValue}
              errorMap={errorMap}
              onChange={(newValue, errors) => {
                setErrorMap({
                  ...errorMap,
                  ...errors,
                });
                handleChange('valuesMap', {
                  ...valuesMap,
                  [attribute.id]: { ...(attValue || {}), value: newValue },
                });
              }}
            />
          );
        })}
      </>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            {`${attributeItem?.id || ''}`.trim() === ''
              ? 'Creating'
              : 'Updating'}{' '}
            a {attributeSetCode.replace('_', ' ')}
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving || isLoading,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving || isLoading,
            iconBefore: Icons.SendIcon,
            btnText:
              `${attributeItem?.id || ''}`.trim() === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: getBody(),
      })}
    />
  );
};

export default AttributeItemEditPopupBtn;
