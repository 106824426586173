import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';
import SectionDiv from '../../../../frameWork/SectionDiv';
import { AttributeSetCodes } from '../../../../../types/attribute/iAttributeSet';
import AttributeItemEditTable from '../../../../attribute/AttributeItemEditTable';
import entityNames from '../../../../../helpers/EntityNames';

type iBuildDetailsContractFencing = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;

const BuildDetailsContractFencing = ({
  build,
  isDisabled = false,
}: iBuildDetailsContractFencing) => {
  return (
    <Wrapper>
      {[
        {
          title: 'Fencing',
          attributeSetCode: AttributeSetCodes.Fencing,
        },
      ].map((section) => {
        return (
          <SectionDiv key={section.attributeSetCode}>
            <AttributeItemEditTable
              allowCreate={!isDisabled}
              allowEdit={!isDisabled}
              allowDelete={!isDisabled}
              title={<h4>{section.title}</h4>}
              attributeSetCode={section.attributeSetCode}
              entityId={build.id}
              entityName={entityNames.Build}
              needQty={false}
              needArea={false}
            />
          </SectionDiv>
        );
      })}
    </Wrapper>
  );
};

export default BuildDetailsContractFencing;
