import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { getErrorProps, iErrorMap } from '../form/FormError';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import TextField from '../frameWork/TextField';
import AttributeService from '../../services/attribute/AttributeService';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import Select from '../frameWork/Select';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import TextArea from '../frameWork/TextArea';
import * as _ from 'lodash';

export type iAttributeEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  attributeSet: iAttributeSet;
  newSort?: number;
  isDisabled?: boolean;
  attribute?: iAttribute;
  onSaved?: (saved: iAttribute, isCreated: boolean) => void;
};
const AttributeEditPopupBtn = ({
  attribute,
  attributeSet,
  onSaved,
  newSort = 0,
  isDisabled = false,
  ...props
}: iAttributeEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
  };

  const getChangedOptions = () => {
    const changedSettings = editingData.settings || attribute?.settings || {};
    const options = 'options' in changedSettings ? changedSettings.options : [];
    return _.uniq(
      options
        .map((option: string) => `${option || ''}`.trim())
        .filter((option: string) => option !== ''),
    );
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const data = { ...(attribute || {}), ...editingData };
    if (`${data.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    if (`${data.type || ''}`.trim() === '') {
      errors.type = ['Type is required'];
    }
    if (
      data.type === AttributeTypes.DROPDOWN &&
      getChangedOptions().length <= 0
    ) {
      errors.options = ['Options are required'];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const attributeId = `${attribute?.id || ''}`.trim();
    const submittingData = {
      ...editingData,
      ...(attributeId === '' ? { sort: newSort } : {}),
      attributeSetCode: attributeSet.code,
      settings: {
        ...(attribute?.settings || {}),
        options: getChangedOptions(),
      },
    };
    setIsSaving(true);
    const getFnc = () =>
      attributeId === ''
        ? AttributeService.create(submittingData)
        : AttributeService.update(attributeId, submittingData);

    getFnc()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, attributeId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const handleDataChanged = (fieldName: string, newValue: string | boolean) => {
    setEditingData({
      ...editingData,
      [fieldName]: newValue,
    });
  };

  const getSelectedAttributeType = () =>
    `${editingData.type || attribute?.type || ''}`.trim();

  const getSelectedAttributeTypeOption = () => {
    const type = getSelectedAttributeType();
    if (type === '') {
      return undefined;
    }
    return { label: type, value: type };
  };

  const getOptionTextArea = () => {
    const type = getSelectedAttributeType();
    if (type !== AttributeTypes.DROPDOWN) {
      return null;
    }
    const settings =
      editingData.settings === undefined
        ? attribute?.settings || {}
        : editingData.settings;
    const options = 'options' in settings ? settings.options : [];
    return (
      <TextArea
        label={`Options`}
        testId={'options'}
        isRequired
        isDisabled={isSaving || isDisabled}
        value={options.join('\n')}
        helperMsg={`Each Option Per Line`}
        {...getErrorProps({
          fieldName: 'options',
          error: errorMap,
        })}
        onChange={(event) => {
          setEditingData({
            ...editingData,
            settings: {
              ...settings,
              options: event.target.value.split('\n'),
            },
          });
        }}
      />
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title:
          `${attribute?.id || ''}`.trim() === '' ? (
            <>
              Creating a new attribute under{' '}
              <small>{attributeSet.name || ''}</small>
            </>
          ) : (
            <>
              Editing attribute{' '}
              <small>
                {attributeSet.name} / {attribute?.name || ''}
              </small>
            </>
          ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText:
              `${attribute?.id || ''}`.trim() === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <div>
            <TextField
              isRequired
              label={'Name'}
              testId={'name'}
              placeholder={'The name of the attribute'}
              isDisabled={isSaving || isDisabled}
              value={editingData.name || attribute?.name || ''}
              {...getErrorProps({
                fieldName: 'name',
                error: errorMap,
              })}
              onChange={(event) =>
                handleDataChanged(
                  'name',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  event.target.value || '',
                )
              }
            />
            <Select
              isRequired
              label={'Type'}
              testId={'type'}
              isDisabled={isSaving || isDisabled}
              value={getSelectedAttributeTypeOption()}
              {...getErrorProps({
                fieldName: 'type',
                error: errorMap,
              })}
              onChange={(option) =>
                handleDataChanged('type', option?.value || '')
              }
              options={Object.values(AttributeTypes).map((type) => ({
                label: type,
                value: type,
              }))}
            />
            <Toggle
              isRequired
              label={'Is Required?'}
              testId={'isRequired'}
              isChecked={
                editingData.isRequired !== undefined
                  ? editingData.isRequired
                  : attribute?.isRequired === true
              }
              isDisabled={isSaving || isDisabled}
              onChange={(event) =>
                handleDataChanged('isRequired', event.target.checked)
              }
            />
            {getOptionTextArea()}
          </div>
        ),
      })}
    />
  );
};

export default AttributeEditPopupBtn;
