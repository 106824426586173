import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Flex from '../frameWork/Flex';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import AttributeItemService from '../../services/attribute/AttributeItemService';
import AttributeService from '../../services/attribute/AttributeService';
import DynamicTableHelper, {
  iCellParams,
} from '../../helpers/DynamicTableHelper';
import AttributeValueService from '../../services/attribute/AttributeValueService';
import AttributeItemEditPopupBtn from './AttributeItemEditPopupBtn';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import Icons from '../frameWork/Icons';
import iAttributeValue from '../../types/attribute/iAttributeValue';
import MathHelper from '../../helpers/MathHelper';
import { iRenderPopupBtnFn } from '../common/PopupBtn';

const Wrapper = styled.div`
  width: 100%;
  td.dynamic-tbl-cell-area {
    width: 100px;
  }
  td.dynamic-tbl-cell-qty {
    width: 60px;
  }
  td.dynamic-tbl-cell-btns {
    text-align: right;
    .btns-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

type iAttributeItemValueMap = {
  [key: string]: { [index: string]: iAttributeValue };
};
type iAttributeValueEditTable = {
  attributeSetCode: AttributeSetCodes;
  className?: string;
  testId?: string;
  entityId: string;
  entityName: string;
  allowCreate?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  title: ReactNode;
  needQty?: boolean;
  needArea?: boolean;
};
const AttributeItemEditTable = ({
  attributeSetCode,
  className = '',
  testId = '',
  entityId,
  entityName,
  allowCreate = true,
  allowEdit = true,
  allowDelete = true,
  needQty = true,
  needArea = true,
  title,
}: iAttributeValueEditTable) => {
  const testIdStr = `${testId}-attribute-value-edit-table`;
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const { state, renderDataTable, renderDeleteBtn, onRefresh } =
    useListCrudHook<iAttributeItemWithValueMap>({
      getFn: async () => {
        const result = await Promise.all([
          AttributeItemService.getAll({
            where: JSON.stringify({
              isActive: true,
              entityId,
              entityName,
              attributeSetCode,
            }),
            include: 'HouseArea',
            perPage: 999999,
          }),
          AttributeService.getAll({
            where: JSON.stringify({
              isActive: true,
              attributeSetCode,
            }),
            sort: 'sort:ASC',
            perPage: 999999,
          }),
        ]);
        const attItems = result[0].data || [];
        const attValuesMap: iAttributeItemValueMap = (
          attItems.length <= 0
            ? []
            : (
                await AttributeValueService.getAll({
                  where: JSON.stringify({
                    isActive: true,
                    itemId: attItems.map((item) => item.id),
                    attributeSetCode,
                  }),
                  perPage: 999999,
                })
              )?.data || []
        ).reduce((map, attValue) => {
          const attrItemId = attValue.itemId;
          const attributeId = attValue.attributeId;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const valueMap = attrItemId in map ? map[attrItemId] : {};
          return {
            ...map,
            [attrItemId]: {
              ...valueMap,
              [attributeId]: attValue,
            },
          };
        }, {});
        setAttributes(result[1].data || []);
        return {
          ...result[0],
          data: attItems
            .sort((a, b) => {
              return `${a.HouseArea?.name || ''}`.trim() >
                `${b.HouseArea?.name || ''}`.trim()
                ? 1
                : -1;
            })
            .map((data) => {
              return {
                ...data,
                valuesMap: data.id in attValuesMap ? attValuesMap[data.id] : {},
              };
            }),
        };
      },
    });

  const getEditBtn = (
    data?: iAttributeItemWithValueMap,
    renderBtn?: iRenderPopupBtnFn,
    btnClassName?: string,
  ) => {
    const name = attributeSetCode.replace('_', ' ');
    const dataId = `${data?.id || ''}`.trim();
    const label =
      dataId === '' ? `Create a new ${name}` : `Updating this ${name}`;
    return (
      <AttributeItemEditPopupBtn
        needQty={needQty}
        needArea={needArea}
        attributeItem={data}
        attributeSetCode={attributeSetCode}
        entityId={entityId}
        entityName={entityName}
        onSaved={() => onRefresh()}
        renderBtn={(onClick) => {
          if (renderBtn) {
            return renderBtn(onClick);
          }
          return getCreateIconBtn({
            className: btnClassName,
            onClick: onClick,
            testId: testId || `create-edit-btn-${attributeSetCode}`,
            appearance: 'subtle',
            label,
            isTooltipDisabled: false,
            icon: () =>
              dataId === '' ? (
                <Icons.AddIcon size="small" label={''} />
              ) : (
                <Icons.EditFilledIcon size="small" label={''} />
              ),
          });
        }}
      />
    );
  };

  const displayValue = (attribute: iAttribute, attValue?: iAttributeValue) => {
    const value = `${attValue?.value || ''}`.trim();
    if (attribute.type === AttributeTypes.CHECKBOX) {
      return DynamicTableHelper.getCheckedIcon(value === 'Y');
    }
    return value;
  };

  const getColumns = () => {
    return [
      ...(needArea === true
        ? [
            {
              key: 'area',
              header: 'Area',
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return `${data.HouseArea?.name || ''}`;
              },
            },
          ]
        : []),
      ...(needQty === true
        ? [
            {
              key: 'qty',
              header: 'Qty',
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return `${data.qty || ''}`;
              },
            },
          ]
        : []),
      ...attributes.map((attribute) => {
        return {
          key: attribute.id,
          header: attribute.name,
          cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
            const valueMap = data.valuesMap || {};
            return attribute.id in valueMap
              ? displayValue(attribute, valueMap[attribute.id])
              : null;
          },
        };
      }),
      ...(allowDelete !== true && allowEdit !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return (
                  <div className={'btns-wrapper'}>
                    {allowEdit && getEditBtn(data)}
                    {allowDelete &&
                      renderDeleteBtn({
                        deletingModel: data,
                        deleteFnc: async () => {
                          const values = Object.values(data.valuesMap || {});
                          return Promise.all([
                            AttributeItemService.deactivate(data.id),
                            ...values.map((value) =>
                              AttributeValueService.deactivate(value.id),
                            ),
                          ]);
                        },
                        getDisplayName: () =>
                          attributeSetCode.replace('_', ' '),
                      })}
                  </div>
                );
              },
            },
          ]),
    ];
  };

  const getTotalQtyDiv = () => {
    if (needQty !== true) {
      return null;
    }
    const totalQty = (state.data.data || []).reduce((sum, attributeItem) => {
      return MathHelper.add(sum, attributeItem.qty);
    }, 0);
    return <small className={'total-qty'}>(Total: {totalQty})</small>;
  };

  return (
    <Wrapper
      className={`AttributeValueEditTable ${className}`}
      data-testid={testIdStr}
    >
      <PageTitleWithCreateBtn
        createBtn={
          allowCreate ? getEditBtn(undefined, undefined, 'size-sm') : <div />
        }
        title={
          <Flex className={'align-items-end gap-05'}>
            <div>{title}</div>
            {getTotalQtyDiv()}
          </Flex>
        }
      />
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: `${testIdStr}-table`,
        },
      })}
    </Wrapper>
  );
};

export default AttributeItemEditTable;
